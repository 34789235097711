import ReactDOM from 'react-dom';
import React, { useState, useEffect } from 'react';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';


const CustomRouterPrompt = (
  message,
  callback,
  confirmOpen,
  setConfirmOpen) => {

  const container = document.createElement("div");
  container.setAttribute('custom-confirm-view', "");

  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    setConfirmOpen(false)
  }

  const handleCancel = callbackState => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
    setConfirmOpen(false)
  }

  const handleClose = () => {
    setConfirmOpen(false);
  }

  document.body.append(container);

  ReactDOM.render(

    <CModal closeOnBackdrop={false}
      show={confirmOpen}
      onClose={handleCancel}
    // when={true}
    >
      <CModalHeader closeButton className="warningMsg">
        <span>
          <span className="fa fa-exclamation-triangle warningIcon" aria-hidden="true"></span>&nbsp;
         Warning!!
         </span>

      </CModalHeader>
      <CModalBody style={{textAlign:"center"}}>
        {message}
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={handleCancel}
          style={{ color: "black" }}
          className="cancelButton"
        >Cancel</CButton>
        <CButton
          color="primary"
          onClick={handleConfirm}
          // style={{color:"black"}}
          className="continueButton"
        >Continue</CButton>
      </CModalFooter>
    </CModal>,
    container
  )
}

export const CustomPrompt = (props) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    setIsConfirmOpen(props.show);
  }, [props.show]);

  const handleCancel = () => {
    // setIsConfirmOpen(!modal);
    props.handleCancel();
  }

  const handleConfirm = () => {
    // setIsConfirmOpen(!modal);
    props.handleConfirm();
  }


  return (

    <CModal closeOnBackdrop={false}
      show={isConfirmOpen}
      onClose={handleCancel}
    >
      <CModalHeader closeButton className='warningMsg'>
        <span>
          <span className="fa fa-exclamation-triangle warningIcon" aria-hidden="true" ></span>&nbsp;
         Warning!!
         </span>
      </CModalHeader>
      <CModalBody style={{textAlign:"center"}}>
        {props.msg}
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={handleCancel}
          className="cancelButton"
        >Cancel</CButton>
        <CButton
          color="primary"
          onClick={handleConfirm}
          // style={{color:"black"}}
          className="continueButton"
        >Continue</CButton>
      </CModalFooter>
    </CModal>
  )
}

export default CustomRouterPrompt;























// import React, { useState, useEffect } from 'react';
// import { CModal, CModalHeader, CModalBody, CModalFooter, CButton } from '@coreui/react'


// export default function CustomRouterPrompt(props) {

//   const [modal, setModal] = useState(true);

//   useEffect(() => {
//     setModal(props.show);
//   }, [props.show]);

//   const closePopup = (cb) => {
//     //props.onCancel();
//     setModal(false);
//     console.log(cb)
//   }

//   return (
//     <>
//       <CModal
//         show={modal}
//         onClose={closePopup}
//         // when={true}
//       >
//         <CModalHeader closeButton>
//             Warning!!
//             </CModalHeader>
//         <CModalBody>
//         You have unsaved changes, Do you want to continue without saving?
//         </CModalBody>
//         <CModalFooter>
//           <CButton
//             color="secondary"
//             onClick={closePopup}
//           >Cancel</CButton>
//             <CButton
//             color="primary"
//             // onClick={closePopup}
//           >Continue</CButton>
//         </CModalFooter>
//       </CModal>
//     </>
//   )
// }