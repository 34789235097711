import React, { useState } from "react";
import {
  BrowserRouter,
  HashRouter,
  Prompt,
  Route,
  Router,
  Switch,
} from "react-router-dom";
import "./scss/style.scss";
import RedirectToLoginRoute from "./views/routes/RedirectToLoginRoute";
import GuestRoute from "./views/routes/GuestRoute";
import UserRoute from "./views/routes/UserRoute";
import UserLeaveConfirmation from "./views/component/UserLeaveConfirmation";
import CustomRouterPrompt from "./views/component/CustomRouterPrompt";
import UploadImage_Component from "./views/pages/job_add/UploadImage_Component";
import { ConvertToExcel } from "./views/component/excel/CovertToExcel";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ResetPassword = React.lazy(() =>
  import("./views/pages/reset_password/ResetPassword")
);

export default function App() {
  const [confirmOpen, setConfirmOpen] = useState(true);
  return (
    <HashRouter
      getUserConfirmation={(message, callback) => {
        return CustomRouterPrompt(
          message,
          callback,
          confirmOpen,
          setConfirmOpen
        );
      }}
    >
      {/* <HashRouter> */}
      <React.Suspense fallback={loading}>
        <Switch>
          {/* <Route exact path="/add" name="NewJob" render={props => <AddNewJob {...props}/>} /> */}
          {/* <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
          {/* <Route getUserConfirmation={(message, callback) => {
            return UserLeaveConfirmation(message, callback, confirmOpen, setConfirmOpen)
          }} /> */}
          <GuestRoute component={() => <Login />} exact path="/login" name="Login Page" />
          <Route
            path="/resetPassword"
            name="Reset Password"
            component={() => <ResetPassword />}
          />
          {/* <Route  path="/excel" name="Reset Password" component={ConvertToExcel} /> */}
          <UserRoute path="/" name="Home" component={() => <TheLayout />} />
          <RedirectToLoginRoute component={() => ""} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}
