import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CRow } from '@coreui/react';
import React from 'react';
import './ob-prompt.styles.scss'

const OBPrompt = ({ changePromptStatus, setChangePromptStatus, setConfirm, message }) => {
    return (
        <CRow>
            <CModal closeOnBackdrop={false} show={changePromptStatus} className="obprompt">
                <CModalHeader className="warningMsg">
                    <span>
                        <span className="fa fa-exclamation-triangle warningIcon" aria-hidden="true" ></span>&nbsp;
                        Warning!!
            </span>
                </CModalHeader>
                <CModalBody style={{ textAlign: "center" }}>{message}</CModalBody>
                <CModalFooter>
                    <CButton className="cancel" onClick={() => { setChangePromptStatus(false); setConfirm(false) }}>Cancel</CButton>
                    <CButton className="yes" onClick={() => { setChangePromptStatus(false); setConfirm(true) }}>Yes</CButton>
                </CModalFooter>
            </CModal>
        </CRow>
    )
}

export default OBPrompt