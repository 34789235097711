import React from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const GuestRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const authToken = useSelector(state => state.authToken);
  const storedToken = localStorage.getItem("obadminkey");
  let isAuthenticated = !!storedToken;
  console.log("GuestRoute ==> isAuthenticated: ", isAuthenticated);
  console.log("GuestRoute ==> storedToken: ", storedToken);
  if (isAuthenticated) {
    console.log("GuestRoute ==> authToken: ", authToken);
    if (storedToken !== authToken) {
      dispatch({ type: "token/save", authToken: storedToken });
    }
  }
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/dashboard"
                //state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

GuestRoute.propTypes = {
  component: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    authToken: state.authToken,
    isAuthenticated: !!state.authToken
  };
}

// export default connect(mapStateToProps)(GuestRoute);
export default GuestRoute;
