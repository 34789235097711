import React from 'react'
import Toast from 'react-bootstrap/Toast'
import ToastHeader from 'react-bootstrap/ToastHeader'
import ToastBody from 'react-bootstrap/ToastBody'

const OBToast = ({className, TypeOftoast, show, message, setToastStatus }) => {

    return (
        <Toast className="ob-toast" show={show} onClose={() => setToastStatus(false)}
         animation={true} style={{ position: "fixed", minWidth:'400px'}} delay={5000} autohide>
            <Toast.Header style={(TypeOftoast === "SUCCESS") ?
                { backgroundColor: '#e8f5e9', color: '#1b5e20', fontSize:'1.3rem' } :
                { backgroundColor: "#ffcccb", color: 'red', fontSize:'1.3rem' }}>
                <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" onClick={() => setToastStatus(false)} />
                {
                    (TypeOftoast === "SUCCESS") ?
                        <i className="fa fa-check-circle mr-2 " aria-hidden="true"></i> :
                        <i className="fa fa-info-circle mr-2 " aria-hidden="true"></i>
                }
                {
                    (TypeOftoast === "SUCCESS") ?
                        <strong className="mr-auto">Success</strong> :
                        <strong className="mr-auto">Error</strong>
                }

                {/* <small>11 mins ago</small> */}
            </Toast.Header>
            <Toast.Body style={(TypeOftoast === "SUCCESS") ?
                { backgroundColor: "#e8f5e9", color: '#1b5e20', display: "flex", justifyContent: "center",fontSize:'1.1rem',} :
                { backgroundColor: "#ffcccb", color: 'red', display: "flex", justifyContent: "center",fontSize:'1.1rem'}}>

                <font>{message}</font> </Toast.Body>
        </Toast>
    )
}


export default OBToast