import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const RedirectToLoginRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => (
    <Route
      {...rest}
      render={props => (
        <Redirect
          to={{
            pathname: "/login"
            //state: { from: props.location }
          }}
        />
      )}
    />
  );

RedirectToLoginRoute.propTypes = {
  component: PropTypes.func.isRequired
};
export default RedirectToLoginRoute;
