export default function logout(dispatch, history,setToastType, setToastStatus, setToastMessage, isManualLogout = false) {
  console.log("logoutFunction ===>>> logout()");
  dispatch({ type: "token/remove" });
  dispatch({ type: "isSessionExpired/set", isSessionExpired: !isManualLogout });
  localStorage.removeItem("obadminkey");

  // setToastType("SUCCESS");
  // setToastStatus(true);
  // setToastMessage("Logged out successfully");

  setTimeout(() => {
    history.push("/login");
  }, 1000);
}
