import ReactDOM from 'react-dom'
import { Confirm } from 'semantic-ui-react'
import React from 'react'

const UserLeaveConfirmation = (
  message,
  callback,
  confirmOpen,
  setConfirmOpen) => {

  const container = document.createElement("div");
  container.setAttribute('custom-confirm-view', "");

  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    setConfirmOpen(false)
  }

  const handleCancel = callbackState => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
    setConfirmOpen(false)
  }

  document.body.append(container);

  ReactDOM.render(
    <Confirm
      open={confirmOpen}
      cancelButton='Cancel'
      confirmButton="Continue"
      // content={message}
      content="You have unsaved changes, Do you want to continue without saving?"
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />,
    container
  )
}

export default UserLeaveConfirmation;