import { createStore } from "redux";

const initialState = {
  sidebarShow: "responsive",
  profile: null,
  authToken: null,
  isFormDirty: false,
  selectedJobListTab: "OPEN",
  selectedJobListPageNumber: 1,
  editDirectoryCategoryData: null,
  filter: {
    selectedCategory: null,
    selectedEmployer: null,
    selectedDate: null,
  },
  adminFirstName: "",
  adminLastName: "",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "token/save":
      return { ...state, ...rest };
    case "token/remove":
      return { ...state, ...rest, authToken: null };
    case "user/save":
      return { ...state, ...rest };
    case "empList/save":
      return { ...state, ...rest };
    case "job/edit/save":
      return { ...state, ...rest };
    case "job/edit/remove":
      return { ...state, ...rest, jobToBeEdited: null };
    case "jobList/save":
      return { ...state, ...rest };
    case "isFormDirty/set":
      return { ...state, ...rest };
    case "image/upload":
      return { ...state, ...rest };
    case "selectedJobListTab/set":
      return { ...state, ...rest };
    case "selectedJobListPageNumber/set":
      return { ...state, ...rest };
    case "directoryCategory/edit":
      return { ...state, ...rest };
    case "isSessionExpired/set":
      return { ...state, ...rest };
    case "filter/set":
      return { ...state, ...rest };
    case "defaultImageDirectory/list":
      return { ...state, ...rest };
    case "search/set":
      return { ...state, ...rest };
    case "adminFirstName/save":
      return { ...state, ...rest };
    case "adminLastName/save":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
