import React, { useState, useEffect } from 'react'
import { CCard, CCardBody, CCardHeader, CCol, CLabel, CRow, CButton, CCollapse, CInput, CPopover, CCardFooter, CModal, CHeader, CModalBody, CModalFooter, CModalHeader, CSelect } from '@coreui/react'
import Dropzone from 'react-dropzone'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import api from '../../../api';
import { result, sortedIndex } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { API_SERVER_URL, IMAGE_BASE_URL } from '../../../app_constants';
import OBToast from '../../component/toast';
import { Toast } from 'react-bootstrap';
import { timeout } from 'd3';
import { Multiselect } from 'multiselect-react-dropdown';
import logout from '../../../common/logoutFunction/logout';
import { useHistory } from 'react-router';
import OBPrompt from '../../component/OB_Prompt/OBPrompt';


const imageMaxSize = 10000000; //bytes  
const acceptedFileType = 'image/png,image/jpg,image/jpeg,image/PNG,image/JPG,image/JPGE';
const acceptedFileTypeArray = acceptedFileType.split(',').map((item) => { return item.trim() });

export default function UploadImage_Component(props) {
  const [imgSrc, setImgSrc] = useState(null);
  const [cropSettingForMobile, setCropSettingForMobile] = useState({
    unit: 'px',
    x: 0,
    y: 0,
    width: 500,
    aspect: 1 / 1
  });
  const [minRelativeMobileWidth, setMinRelativeMobileWidth] = useState(500);
  const [minRelativeDesktopWidth, setMinRelativeDesktopWidth] = useState(1200);
  const [croppedImageUrlOfMobile, setCroppedImageUrlOfMobile] = useState(null);
  const [croppedImageBlobOfMobile, setCroppedImageBlobOfMobile] = useState(null);
  const [imageRefForMobile, setImageRefForMobile] = useState('');
  const [imageRefForDesktop, setImageRefForDesktop] = useState('');
  const [croppedImageUrlOfDesktop, setCroppedImageUrlDesk] = useState(null);
  const [croppedImageBlobOfDesktop, setCroppedImageBlobOfDesk] = useState(null);
  const [cropSettingForDesktop, setCropSettingForDesktop] = useState({
    unit: 'px',
    x: 0,
    y: 0,
    width: 1200,
    aspect: 10 / 4
  });
  // const [cropedImage, setcropedImage] = useState(false);
  const [showDropZoneFlag, setShowDropZoneFlag] = useState(true);
  const [imgErr, setimgErr] = useState('');
  const [toastType, setToastType] = useState('');
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isWarningMsgShow, setIsWarningMsgShow] = useState(false);
  const [isChangeImageBtnDisable, setIsChangeImageBtnDisable] = useState(true);
  const [isDropDownModalOpen, setIsDropDownModalOpen] = useState(false);
  const [defaultImageList, setDefaultImageList] = useState([]);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [selectedDefaultImage, setSelectedDefaultImage] = useState('');
  const [changePromptStatus, setChangePromptStatus] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [singleImageData, setSingleImageData] = useState('');
  const [index, setIndex] = useState();

  let history = useHistory();
  let dispatch = useDispatch();
  const authToken = useSelector(state => state.authToken);

  // useEffect(() => {
  //   var bgClr = document.getElementById('bgClr');
  //   if (bgClr !== null) {


  //     bgClr.addEventListener('click', function () {
  //       bgClr.style.backgroundColor = "gray"
  //     })
  //   }

  // }, [])

  //props.job
  useEffect(() => {
    console.log("JOBB", props.job);
    console.log("props.job banner ==> ", props.job.imagePathBanner);
    console.log("props.job card ==> ", props.job.imagePathCard);

    if (props.job && props.job.id) {
      if (props.job.imagePathBanner && props.job.imagePathCard) {
        props.updateIsDirtyStep5(false);
        setIsChangeImageBtnDisable(false);
      }
    }


  }, [props.job])

  useEffect(() => {
    setCropSettingForMobile({
      unit: 'px',
      x: 0,
      y: 0,
      width: minRelativeMobileWidth,
      aspect: 1 / 1
    });

    setCropSettingForDesktop({
      unit: 'px',
      x: 0,
      y: 0,
      width: minRelativeDesktopWidth,
      aspect: 10 / 4
    });

  }, [minRelativeMobileWidth, minRelativeDesktopWidth, imageRefForMobile, imageRefForDesktop])

  useEffect(() => {
    if (confirm) {
      let data = {
        jobId: props.job.id,
        mobileScreenImagePath: singleImageData.cardImageFile,
        desktopScreenImagePath: singleImageData.bannerImageFile
      }

      console.log("DATATATTA ", data)
      props.UploadDefaultImage(data);
      setShowDropZoneFlag(true)
      setIsDropDownModalOpen(false);
      setShowDropdownMenu(false);
      props.updateIsDirtyStep5(false);
      setChangePromptStatus(false);

    }
    setConfirm(false);
  }, [confirm])

  useEffect(() => {
    if (!changePromptStatus) {
      setIndex(null);
    }
  }, [changePromptStatus])

  // when image is select by click on dropzone field
  const handleImageOnDrop = (files, rejectedFiles) => {
    console.log("rejectedFiles ....", rejectedFiles);
    if (rejectedFiles && rejectedFiles.length > 0) {
      switch (rejectedFiles[0].errors[0].code) {
        case "file-invalid-type":
          setToastStatus(true);
          setToastMessage("Image file type must be png/ jpg/ jpeg");
          break;

        case "file-too-large":
          setToastStatus(true);
          setToastMessage("File too large, maximum size is 10 MB");
          break;

        default:
          break;
      }
      setShowDropZoneFlag(true);
      setIsChangeImageBtnDisable(false);
      props.updateIsDirtyStep5(false);
    }


    if (files && files.length > 0) {
      const isVerified = verifyFile(files);
      if (isVerified) {
        setIsChangeImageBtnDisable(false);
        const currentFile = files[0];
        console.log("handleImageOnDrop ==> currentFile: ", currentFile);
        const fileItemReader = new FileReader();

        fileItemReader.addEventListener("load", () => {
          setImgSrc(fileItemReader.result);
        }, false);
        fileItemReader.readAsDataURL(currentFile);
      }
    }
  }

  // to varify selected file is image file or not and check size of file
  const verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;

      console.log("file  ...", currentFileType);
      console.log("file type ...", typeof (currentFileType));

      if (currentFileSize > imageMaxSize) {
        setToastStatus(true);
        setToastMessage("This file is not allowed" + currentFileSize + "byte is to large");
        return false;
      }
      if (!acceptedFileTypeArray.includes(currentFileType)) {
        setToastStatus(true);
        setToastMessage("This file is not allowed. Only image files are allowed !!");
        return false;
      }
      return true;
    }
  }

  async function handleOncropCompleteMobile(imageCropAreaForMobile) {
    if (imageRefForMobile && imageCropAreaForMobile.width && imageCropAreaForMobile.height) {
      const croppedImageForMobile = await getCroppedImgMo(
        imageRefForMobile,
        imageCropAreaForMobile,
        'newFile.jpeg'
      );
      setCroppedImageUrlOfMobile(croppedImageForMobile);
    }
  }

  // when image is selected for crop
  function handleOnImageLoadMob(image) {
    console.log("cropped iamge .. ", croppedImageUrlOfMobile)
    if (image.naturalWidth < 1200 || image.naturalHeight < 500) {
      setIsWarningMsgShow(true);
    } else {
      setIsChangeImageBtnDisable(false);
      setIsWarningMsgShow(false);
    }
    setMinRelativeMobileWidth(500 * image.width / image.naturalWidth);
    setMinRelativeDesktopWidth(1200 * image.width / image.naturalWidth);

    if (image !== null) {
      props.updateIsDirtyStep5(true);
    }
    setImageRefForMobile(image);
    // return false;
  }

  // when image is crop(in progress) onchange method
  const HandleOnCropMobile = (imageCropAreaForMobile) => {
    setCropSettingForMobile(imageCropAreaForMobile);
  }

  // when cropping is done
  // const handleOncropCompleteMobile = async (imageCropAreaForMobile) => {
  //   makeClientCropMo(imageCropAreaForMobile);
  // }

  function getCroppedImgMo(image, imageCropAreaForMobile, fileName) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      // scaleX = 1200 / 600;
      // scaleX = 2;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = imageCropAreaForMobile.width;
      canvas.height = imageCropAreaForMobile.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        imageCropAreaForMobile.x * scaleX,
        imageCropAreaForMobile.y * scaleY,
        imageCropAreaForMobile.width * scaleX,
        imageCropAreaForMobile.height * scaleY,
        0,
        0,
        imageCropAreaForMobile.width,
        imageCropAreaForMobile.height
      );

      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        let fileUrl = ''
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        setCroppedImageBlobOfMobile(blob);
        resolve(fileUrl);
      }, 'image/jpeg');
    });

  }

  const handleOnImageLoadDesk = (image) => {
    console.log("iomage path ", image);
    console.log("crop image ..", croppedImageBlobOfDesktop)
    if (image !== null) {
      props.updateIsDirtyStep5(true);
    }
    setImageRefForDesktop(image);
  }

  // on change method for desktop
  const handleOnCropDesk = (imageAreaForDesktop) => {
    // console.log("width of crop image...", imageAreaForDesktop.width);
    console.log("handke on crop ==> ", imageAreaForDesktop)
    console.log("initial crop are ==> ", cropSettingForDesktop)
    setCropSettingForDesktop(imageAreaForDesktop);
  }

  async function handleOncropCompleteDesk(imageAreaForDesktop) {
    if (imageRefForDesktop && imageAreaForDesktop.width && imageAreaForDesktop.height) {
      try {
        const croppedImageForDesktop = await getCroppedImgDesk(
          imageRefForDesktop,
          imageAreaForDesktop,
          'newFile.jpeg'
        );
        setCroppedImageUrlDesk(croppedImageForDesktop);

      } catch (error) {
        if (error.message === 'Network Error') {
          setToastType("FAILURE");
          setToastStatus(true);
          setToastMessage("Please check your network connection");
        }
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          logout(dispatch, history);
        }
        console.log("handleOncropCompleteDesk ==> error: ", error);
      }
    }
  }

  function getCroppedImgDesk(image, imageAreaForDesktop, fileName) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      console.log("width of crop image...", imageAreaForDesktop.width * scaleX);
      // if (imageAreaForDesktop.width * scaleX < 1200) {
      //   reject({ error: "Desktop image can not be less than 1200px!" });
      // } else {
      canvas.width = imageAreaForDesktop.width;
      canvas.height = imageAreaForDesktop.height;
      const ctx = canvas.getContext('2d');

      // var imgD = canvas.toDataURL("image/jpeg");
      // console.log("iamge ==> ", imgD);
      // setTestMobImg(imgD);

      ctx.drawImage(
        image,
        imageAreaForDesktop.x * scaleX,
        imageAreaForDesktop.y * scaleY,
        imageAreaForDesktop.width * scaleX,
        imageAreaForDesktop.height * scaleY,
        0,
        0,
        imageAreaForDesktop.width,
        imageAreaForDesktop.height
      );

      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        let fileUrl = ''
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        setCroppedImageBlobOfDesk(blob);
        resolve(fileUrl);
      }, 'image/jpeg');
    }
      // }
    );
  }

  useEffect(() => {

    api.job.getDefaultImageByDirectoryId(1, authToken)
      .then(result => {
        console.log("Result by directory id ", result);
        if (result.resultCode === 1) {
          setDefaultImageList(result.resultData.defaultImagesList);
        }
      }).catch(error => {
        console.log(error);
        if (error.message === 'Network Error') {
          setToastType("FAILURE");
          setToastStatus(true);
          setToastMessage("Please check your network connection");
        }
        if (!!error.response && !!error.response.status && error.response.status === 401) {
          logout(dispatch, history);
        }
      })
  }, [])

  const UploadDefaultImage = () => {
    console.log(props.job);

    setIsDropDownModalOpen(true);

    scrollUp();

  }

  const handleChangeImage = () => {
    props.updateIsDirtyStep5(false);
    setIsChangeImageBtnDisable(true);
    setShowDropZoneFlag(false);
    setImgSrc(null);
    setImageRefForMobile("");
    setImageRefForDesktop("");
    setCroppedImageUrlOfMobile(null);
    setCroppedImageUrlDesk(null);
    setIsWarningMsgShow(false);
  }

  const UploadCroppedImage = () => {
    console.log("width mo...", imgSrc.naturalWidth);
    console.log("width mo...", imgSrc.naturalHeight);

    // console.log("croped mo img ..", croppedImageUrlOfMobile);
    // console.log("croped desk img ..", croppedImageUrlOfDesktop);
    if (imgSrc.naturalWidth < 1200 && imgSrc.naturalHeight < 500) {
      setToastType("Failure")
      setToastStatus(true);
      setToastMessage("Cropped image for mobile should be minimum 500px wide");
      // }
      //  else if (imgSrc.width <= 1200) {
      //   setToastType("Failure")
      //   setToastStatus(true);
      //   setToastMessage("Cropped image for desktop should be minimum 1200px wide");
    }
    else {
      console.log("TRUE......");
      setShowDropZoneFlag(true);
      let job = {
        imageBlobUrlForMobile: croppedImageUrlOfMobile,
        imageBlobUrlForDesktop: croppedImageUrlOfDesktop,
        blobForMobile: croppedImageBlobOfMobile,
        blobForDesktop: croppedImageBlobOfDesktop
      }
      props.UploadImage(job);
      props.updateIsDirtyStep5(false);
    }
    scrollUp();
  }

  const scrollUp = () => {
    window.scrollTo(0, 0);
  }


  return (
    <React.Fragment>
      <CModal className='modal-img-resp p-1' show={isDropDownModalOpen} closeOnBackdrop={false}>
        <CModalHeader style={{ backgroundColor: '#3c4b64' }}>
          <h6 style={{ color: 'white' }}>
            Select Default Image
          </h6>
        </CModalHeader>
        <CModalBody className='p-1 modal-image'
        // style={{ textAlign: "center", backgroundColor: '#636f83', overflowY: 'auto', height: '600px' }}
        >

          <div className="" >
            <ol style={{ listStyleType: "none" }}
              className="mt-3  two-column"
            >
              {
                defaultImageList.map((image, idx) =>
                  <li key={idx} className="mb-4 mr-5 ml-1"
                    value={selectedDefaultImage}
                    onClick={() => {
                      console.log("directory id  iamge  ", image.directoryId)
                      console.log("LIST  iamge  ", image);
                      setIndex(idx);
                      setSingleImageData(image);
                      setChangePromptStatus(true);
                      console.log("ID   ", idx);
                    }}>
                    <img
                      className="img-resp  menu-onHover"
                      id="bgClr"
                      src={IMAGE_BASE_URL + image.cardImageFile} alt="img_option"
                      // width="170" height='170'
                      style={idx == index ? { border: '2px solid rgb(63, 167, 207)' } : { border: '' }}
                    />

                  </li>
                )
              }
            </ol>
            {/* </CCollapse> */}
          </div>

        </CModalBody>
        <CModalFooter className="footer-width" style={{ backgroundColor: '#3c4b64' }}>
          <CPopover content="Cancel">
            <CButton color="secondary"
              onClick={() => {
                setIsDropDownModalOpen(false);
              }}>
              Cancel
                </CButton>
          </CPopover>
        </CModalFooter>
      </CModal>

      <CModal show={isWarningMsgShow} closeOnBackdrop={false}>
        <CModalHeader className="warningMsg">
          <span>
            <span className="fa fa-exclamation-triangle warningIcon" aria-hidden="true" ></span>&nbsp;
             Warning!!
          </span>
        </CModalHeader>
        <CModalBody style={{ textAlign: "center" }}>
          Image resolution should be minimum 1200px X 500px
        </CModalBody>
        <CModalFooter>
          <CPopover content="Ok">
            <CButton color="primary"
              className="continueButton"
              onClick={() => {
                if (!!props.job.imagePathCard) {
                  setShowDropZoneFlag(true);
                  setIsWarningMsgShow(false);
                  setImageRefForMobile("");
                  setImageRefForDesktop("");
                  setCroppedImageUrlOfMobile(null);
                  setCroppedImageUrlDesk(null);
                  props.updateIsDirtyStep5(false);
                } else {
                  handleChangeImage();
                }
              }}
            >Ok</CButton>
          </CPopover>

        </CModalFooter>
      </CModal>

      <OBPrompt changePromptStatus={changePromptStatus}
        setChangePromptStatus={setChangePromptStatus}
        setConfirm={setConfirm}
        message="Are you sure, you want this default image ?" />

      <CRow>
        <Toast className="toast_upload_Image" show={toastStatus} onClose={() => setToastStatus(false)} animation={true} style={{ position: "absolute" }} animation={true} delay={3000} autohide>
          <Toast.Header style={{ backgroundColor: "#ffcccb", color: 'red' }}>
            <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" onClick={() => setToastStatus(false)} />
            <i className="fa fa-info-circle mr-2 " aria-hidden="true"></i>
            <strong className="mr-auto">Error</strong>
          </Toast.Header>
          <Toast.Body
            style={{ backgroundColor: "#ffcccb", color: 'red', display: "flex", justifyContent: "center" }}>
            <font>{toastMessage} </font> </Toast.Body>
        </Toast>
      </CRow>
      {/*      
      <OBToast
        TypeOftoast={toastType}
        show={toastStatus}
        message={toastMessage}
        setToastStatus={setToastStatus}
      /> */}


      <CCard style={{ marginBottom: 0 }}>
        <CCardHeader> <h5>
          Upload Image
           <span style={{ fontSize: "15px", fontWeight: 400 }}> (Please scroll down to see a preview of all images)</span>
        </h5>
        </CCardHeader>
        {(!props.job || !props.job.imagePathCard) || !showDropZoneFlag ?
          <CCardBody>
            {imgSrc !== null ?
              <div>
                <div>
                  <h4>Image For Mobile View : </h4>
                  <strong>Select image area for mobile view </strong>
                  <br /><br />
                  <div>
                    <ReactCrop src={imgSrc} onImageLoaded={handleOnImageLoadMob} minWidth={minRelativeMobileWidth}
                      onComplete={handleOncropCompleteMobile} crop={cropSettingForMobile} onChange={HandleOnCropMobile} />
                  </div><br />
                  <h6>Preview for mobile : </h6>
                  {croppedImageUrlOfMobile && (
                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrlOfMobile} />
                  )}
                </div><br />
                <div>
                  <h4>Image For Desktop View : </h4>
                  <strong>Select image area for desktop view </strong>

                  <br /><br />
                  <div>
                    <ReactCrop src={imgSrc} onImageLoaded={handleOnImageLoadDesk} minWidth={minRelativeDesktopWidth}
                      onComplete={handleOncropCompleteDesk} crop={cropSettingForDesktop} onChange={handleOnCropDesk} />
                  </div><br />
                  <h6>Preview for desktop : </h6>
                  {croppedImageUrlOfDesktop && (
                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrlOfDesktop} />
                  )}
                </div>
              </div>
              :
              <CRow>
                <CCol>
                  <CCol >
                    <Dropzone onDrop={handleImageOnDrop} className="dropzone" accept={acceptedFileType}
                      multiple={false} maxSize={imageMaxSize} >
                      {({ getRootProps, getInputProps }) => (
                        // <section className="" style={{width:"90%"}}>
                        <div className='for_sm_text' {...getRootProps({ className: 'dropzone' })} style={{ padding: 20 }}>
                          <section >
                            <input {...getInputProps()} />
                            <CRow >
                              <CCol>
                                <p className='resize-p-text' style={{ color: "black" }}>  Drag & drop files and Click to select file(s)<span> </span>
                                  <small lassName='resize-small-text' style={{ color: "black" }}>
                                    (Minimum image resolution should be 1200px X 500px, Maximum size of image is 10MB and it accept png, jpg, jpeg files).
                                 </small>
                                </p>
                              </CCol>
                            </CRow>
                          </section>
                        </div>
                      )}
                    </Dropzone>
                  </CCol>
                </CCol>
              </CRow>
            }
            <pre className="mandetory">{imgErr} </pre>

          </CCardBody>
          :
          <CCardBody>
            <h4>Image For Mobile View : </h4>
            {props.job.imagePathCard && (
              <img alt="Crop" style={{ maxWidth: '100%' }} src={IMAGE_BASE_URL + props.job.imagePathCard} />
            )}
            <br /><br />
            <h4>Image For Desktop View : </h4>
            {props.job.imagePathBanner && (
              <img alt="Crop" style={{ maxWidth: '100%' }} src={IMAGE_BASE_URL + props.job.imagePathBanner} />
            )}
          </CCardBody>

        }
        <CCardFooter>
          <CRow className="action-btn-container d-flex justify-content-space-around">
            <CCol sm="2" md="2" lg='3'></CCol>
            <CCol xs="12" sm="3" md="3" lg="3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CPopover content="Upload Default Image">
                <CButton
                  style={{ backgroundColor: "skyblue", color: "black" }}
                  className="handIconForm"
                  onClick={UploadDefaultImage}>
                  Upload Default Image
                </CButton>
              </CPopover>
            </CCol>
            <CCol xs="12" sm="3" md="3" lg="3" style={{ display: 'flex', justifyContent: 'center' }}>
              <CPopover content="Change Image">
                <CButton
                  style={{ backgroundColor: "skyblue", color: "black" }}
                  className='upload_btn' disabled={isChangeImageBtnDisable}
                  onClick={handleChangeImage}>Change Image</CButton>
              </CPopover>
            </CCol>
            <CCol xs="12" sm="3" md="3" lg="3" style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <CPopover content="Upload">
                <CButton
                  style={{ backgroundColor: "skyblue", color: "black" }}
                  disabled={!croppedImageUrlOfDesktop || !croppedImageUrlOfMobile}
                  onClick={UploadCroppedImage}>Upload</CButton>
              </CPopover>
            </CCol>
            <CCol sm="1" md="1" ></CCol>
          </CRow>
        </CCardFooter>
      </CCard>
      {/* </div> */}
    </React.Fragment >
  )
}




















